<template>
  <div>
    <el-container :style="{ height: height }">
      <el-header style="width: 100%; ">
        <div class="header-left"></div>
        Stages Videos
        <div class="header-right">
          <el-input
            placeholder="Search Videos"
            suffix-icon="el-icon-search"
            v-model="kw"
            clearable
          >
          </el-input>
        </div>
      </el-header>
      <el-container style="margin-top: 60px;">
        <el-aside width="300px">
          <el-menu
            :style="{ 'text-align': 'left', height: '100%' }"
            default-active="All Videos"
            :collapse="false"
            class="el-menu-vertical-demo"
            :unique-opened="true"
            text-color="#606266"
            @select="handleMenuSelect"
          >
            <el-menu-item
              style="font-size: 14px;"
              v-for="item in categories"
              :key="item"
              :index="item"
              >{{ item }}</el-menu-item
            >
          </el-menu>
        </el-aside>
        <el-table :data="filteredVideos" style="margin-left: 20px;" stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="name" :label="label">
            <template slot-scope="scope">
              <el-link :href="scope.row.url" target="_blank">{{
                scope.row.name
              }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="url" label="URL">
            <template slot-scope="scope">
              <el-link :href="scope.row.url" target="_blank">{{
                scope.row.url
              }}</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-container>
      <el-footer height="30px">
        <a href="https://beian.miit.gov.cn/" target="_blank">
          沪ICP备2021011310号-1
        </a>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "VideoPublic",
  data() {
    return {
      kw: "",
      height: window.innerHeight - 15 + "px",
      menuHeight: window.innerHeight - 100 + "px",
      // tableHeight: window.innerHeight - 100,
      categories: [
        "All Videos",
        "Introduction Videos",
        "Tutorial Videos",
        "Symposium Videos",
      ],
      category: "All Videos",
      videos: [
        {
          name:
            "Drew Bailey and Jeff Brehm from Accenture talk about Stages Process Management Software",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Drew+Bailey+and+Jeff+Brehm+from+Accenture+talk+about+Stages+Process+Management+Software.mp4",
        },
        {
          name: "General Motors Interview",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/General+Motors+Interview.mp4",
        },
        {
          name: "Harald Wilhelm Continental talks about Stages",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Harald+Wilhelm+Continental+talks+about+Stages.mp4",
        },
        {
          name:
            "James Greenfield from Continental talks about Stages process management software",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/James+Greenfield+from+Continental+talks+about+Stages+process+management+software.mp4",
        },
        {
          name:
            "Jason Hall from ZF talks about Stages process management software",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Jason+Hall+from+ZF+talks+about+Stages+process+management+software.mp4",
        },
        {
          name: "Kyle Gehan Honeywell - Stages Reference",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Kyle+Gehan+Honeywell+-+Stages+Reference.mp4",
        },
        {
          name:
            "Nick Wilmot from General Motors talks about Stages Process Management Software",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Nick+Wilmot+from+General+Motors+talks+about+Stages+Process+Management+Software.mp4",
        },
        {
          name:
            "Shamron Awwal from Siemens talks about Stages Process Management Software",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Shamron+Awwal+from+Siemens+talks+about+Stages+Process+Management+Software.mp4",
        },
        {
          name:
            "Shriram Patki from Nexteer Automotive talks about Stages Process Management Software",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Shriram+Patki+from+Nexteer+Automotive+talks+about+Stages+Process+Management+Software.mp4",
        },
        {
          name: "Stages Quick Start Guide #1 - Login & Change Password",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Stages+Quick+Start+Guide+%231+-+Login+%26+Change+Password.mp4",
        },
        {
          name:
            "Teresa McCarthy from Lockheed Martin talks about Stages Process Management Software",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Teresa+McCarthy+from+Lockheed+Martin+talks+about+Stages+Process+Management+Software.mp4",
        },
        {
          name:
            "Travis Foust from GM talks about Stages process management software",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Travis+Foust+from+GM+talks+about+Stages+process+management+software.mp4",
        },
        {
          name: "CN_Siemens",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/CN_Siemens.mp4",
        },
        {
          name: "CN_Volkswagen",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/CN_Volkswagen.mp4",
        },
        {
          name: "Stages - JIRA Enactment Integration",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Stages+-+JIRA+Enactment+Integration.mp4",
        },
        {
          name: "通过Stages管理流程–现场演示会",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/%E9%80%9A%E8%BF%87Stages%E7%AE%A1%E7%90%86%E6%B5%81%E7%A8%8B%E2%80%93%E7%8E%B0%E5%9C%BA%E6%BC%94%E7%A4%BA%E4%BC%9A.mp4",
        },
        {
          name: "Stages POC Session 1 -Shfte-20220325",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Shfte+POC+Session+1-20220325.mp4",
        },
        {
          name: "Stages PoC session 2-Shfte-20220401",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Stages+PoC+session+2-20220401.mp4",
        },
        {
          name: "Stages PoC Session 3-Shfte-20220414",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Stages+PoC+Session+3+Shfte-20220414.mp4",
        },
        {
          name: "Stages Demo to Duncan-20210913_153750-Meeting Recording",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Stages+Demo+to+Duncan-20210913_153750-Meeting+Recording.mp4",
        },
        {
          name: "Process Insights Europe 2023",
          desc: "",
          cat: "Introduction Videos",
          url:
            "https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Process+Insights+Europe+2023.mp4",
        },
      ],
    };
  },
  computed: {
    label() {
      return "Videos" + " (" + this.videos.length + ")";
    },
    filteredVideos() {
      return this.videos.filter((value) => {
        if (this.category == "All Videos") {
          return (
            value.name.toLowerCase().indexOf(this.kw.trim().toLowerCase()) > -1
          );
        } else {
          return (
            value.cat == this.category &&
            value.name.toLowerCase().indexOf(this.kw.trim().toLowerCase()) > -1
          );
        }
      });
    },
  },
  methods: {
    handleMenuSelect(index) {
      this.category = index;
    },
  },
};
</script>
<style scoped>
.header-left {
  float: left;
  width: 200px;
  height: 70px;
  background: url(mp.svg) no-repeat;
}
.header-right {
  float: right;
  width: 200px;
  height: 70px;
}
.el-header {
  /* background-color: #B3C0D1; */
  color: #333;
  text-align: center;
  line-height: 60px;
  height: 60px;
  font-size: 20px;
  color: #606266;
  position: fixed;
  background: rgb(240, 240, 240);
  top: 0px;
  z-index: 10;
}
.el-footer {
  height: 30px;
  line-height: 30px;
  height: 30px;
  font-size: 14px;
}
.el-aside {
  /* background-color: #D3DCE6; */
  color: #333;
  text-align: left;
  line-height: 200px;
  padding-left: 10px;
}

.el-main {
  /* background-color: #E9EEF3; */
  color: #333;
  text-align: center;
  line-height: 160px;
}

/* body > .el-container {
    margin-bottom: 40px;
  } */
</style>
